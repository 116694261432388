import { Button, Flex, Text } from '@chakra-ui/react';
import { NextImage } from '@innerwell/chakra/images';
import { type Route } from 'next';
import { type ReactNode } from 'react';

import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import { Icon } from '../Icon';

type Props = {
   text: string | ReactNode;
   subText?: string | ReactNode;
   image: string;
   btnLabel?: string;
   btnLink?: Route;
   onBtnClick?: () => void;
   secondaryBtnLabel?: string;
   onSecondaryBtnClick?: () => void;
};
export const JourneyWelcomeScreen = ({
   text,
   subText,
   btnLink,
   image,
   btnLabel,
   onBtnClick,
   secondaryBtnLabel,
   onSecondaryBtnClick,
}: Props) => {
   return (
      <Flex
         alignItems="center"
         justifyContent="center"
         textAlign="center"
         maxW="650px"
         mx="auto"
         mt={{ base: 10, lg: '120px' }}
         flexDir="column"
         gap={8}
      >
         <Text fontWeight={500}>{text}</Text>
         <NextImage
            w={{ base: '200px', lg: '320px' }}
            h={{ base: '200px', lg: '320px' }}
            rounded="full"
            src={image}
            alt="Image"
         />
         <Text color="accent.peach" fontWeight={600}>
            {subText}
         </Text>
         <Flex
            gap={6}
            alignItems="center"
            flexDir={{ base: 'column', lg: 'row' }}
         >
            {btnLabel ? (
               onBtnClick ? (
                  <Button
                     minW="328px"
                     size="sm"
                     onClick={onBtnClick}
                     rightIcon={<Icon name="chevron-right" w={5} h={5} />}
                  >
                     {btnLabel}
                  </Button>
               ) : btnLink ? (
                  <NextLinkButton size="sm" href={btnLink}>
                     {btnLabel}
                  </NextLinkButton>
               ) : null
            ) : null}
            {secondaryBtnLabel && onSecondaryBtnClick ? (
               <Button
                  color="white"
                  variant="link"
                  onClick={onSecondaryBtnClick}
                  fontSize="sm"
               >
                  {secondaryBtnLabel}
               </Button>
            ) : null}
         </Flex>
      </Flex>
   );
};
