import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { type MoodTrackingRangeEnum } from '@innerwell/dtos';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import useMeasure from 'react-use-measure';

import { usePeriodMoodTracking } from '@/hooks/react-query/usePeriodMoodTracking';

import { Chart } from '@/components/Charts/DailyMoodChart';

import { useChart } from '@/contexts/chart-context';

export const POPOVER_WIDTH = 130;

export type ChartPeriod = 'w' | 'm' | '6m' | 'y';

interface IProps {
   period: ChartPeriod;
   height: string;
   periodSegment: MoodTrackingRangeEnum;
}

export const DailyMoodChart: React.FC<IProps> = ({
   height: wrapperHeight,
   period,
   periodSegment,
}) => {
   const [ref, { width, height }] = useMeasure();
   const { data, setData } = useChart();

   const { data: moodTrackingData, isLoading: isChartLoading } =
      usePeriodMoodTracking({ period, periodSegment });

   useEffect(() => {
      if (moodTrackingData?.segments) {
         setData(
            moodTrackingData.segments.map((d) => ({
               ...d,
               date: DateTime.fromISO(d.date).toMillis(),
            })),
         );
      }
   }, [moodTrackingData, setData]);

   return (
      <Box h={wrapperHeight}>
         <Box ref={ref} h="100%" w="100%" position="relative">
            {isChartLoading ? (
               <Flex
                  alignItems="center"
                  justifyContent="center"
                  gap={2.5}
                  pt={2}
               >
                  <Spinner w={4} h={4} color="text.primary" />
                  <Text color="text.primary">Loading chart data...</Text>
               </Flex>
            ) : null}
            {width && height && data.length ? (
               <Chart
                  width={width}
                  height={height}
                  period={period}
                  periodSegment={periodSegment}
               />
            ) : null}
         </Box>
      </Box>
   );
};
