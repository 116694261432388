import { MoodTrackingEnum } from '@innerwell/dtos';
import { motion } from 'framer-motion';

import { type ChartData } from '@/components/Charts/DailyMoodChart';

export const getMoodColor = (score: MoodTrackingEnum) => {
   if (score === MoodTrackingEnum.ExtremelyBad) {
      return '#881C1C';
   }
   if (score === MoodTrackingEnum.VeryBad) {
      return '#B74B4B';
   }
   if (score === MoodTrackingEnum.Bad) {
      return '#DF7777';
   }
   if (score === MoodTrackingEnum.NeitherBad) {
      return '#A5A9A9';
   }
   if (score === MoodTrackingEnum.Good) {
      return '#66C2C2';
   }
   if (score === MoodTrackingEnum.VeryGood) {
      return '#499C9C';
   }
   if (score === MoodTrackingEnum.ExtremelyGood) {
      return '#417777';
   }
   return '#000';
};

type Props = {
   data: ChartData[];
   circleRadius: number;
   xScale: d3.ScaleTime<number, number, never>;
   yScale: d3.ScaleLinear<number, number, never>;
};

export const ChartPoints: React.FC<Props> = ({
   data,
   circleRadius,
   xScale,
   yScale,
}) => {
   return (
      <>
         {data.map((d, i) => {
            const score =
               'score' in d ? d.score : 'averageScore' in d && d.averageScore;

            if (score) {
               return (
                  <motion.circle
                     initial={{ opacity: 0, scale: 0 }}
                     animate={{ opacity: 1, scale: 1 }}
                     transition={{
                        duration: 1.8,
                        type: 'spring',
                        delay: 0.1 * i * (data.length / 150),
                     }}
                     key={d.date}
                     fill={getMoodColor(score)}
                     r={circleRadius}
                     cx={xScale(d.date)}
                     cy={yScale(score)}
                     strokeWidth={2}
                     stroke="white"
                  />
               );
            }
         })}
      </>
   );
};
