import { motion } from 'framer-motion';
import { DateTime } from 'luxon';

import {
   MoodChartDoseIcon,
   MoodChartMultipleDosesIcon,
} from '@/components/Charts/chart-icons';
import {
   type ChartData,
   type ChartMargin,
} from '@/components/Charts/DailyMoodChart';

interface IProps {
   data: ChartData[];
   xScale: d3.ScaleTime<number, number, never>;
   margin: ChartMargin;
   height: number;
}

export const ChartDoses: React.FC<IProps> = ({
   data,
   xScale,
   margin,
   height,
}) => {
   const dosesTicks = data.filter((entry) =>
      'dosage' in entry ? !!entry.dosage : 'doses' in entry && !!entry.doses,
   );

   return (
      <>
         {dosesTicks.map((data, i) => {
            const date = DateTime.fromMillis(data.date).toJSDate();

            return (
               <g
                  transform={`translate(${xScale(date)}, 0)`}
                  key={DateTime.fromJSDate(date).toISO()}
               >
                  <motion.line
                     initial={{ pathLength: 0 }}
                     animate={{ pathLength: 1 }}
                     transition={{
                        duration: 1,
                        type: 'spring',
                        delay: 0.2 * i,
                     }}
                     y1={height - margin.bottom}
                     y2={margin.top - 10}
                     stroke="#9DE3E3"
                     strokeWidth={2}
                  />
                  <motion.g
                     initial={{ opacity: 0, scale: 0 }}
                     animate={{ opacity: 1, scale: 1 }}
                     transition={{
                        duration: 0.7,
                        type: 'spring',
                        delay: 0.3 + 0.1 * i,
                     }}
                  >
                     <g transform={`translate(-15, ${margin.top - 42})`}>
                        {'dosage' in data ? (
                           <MoodChartDoseIcon />
                        ) : (
                           'doses' in data && (
                              <MoodChartMultipleDosesIcon
                                 amount={data.doses || 0}
                                 transform="translate(0, -10)"
                              />
                           )
                        )}
                     </g>
                  </motion.g>
               </g>
            );
         })}
      </>
   );
};
