import * as d3 from 'd3';
import { motion } from 'framer-motion';
import { Fragment } from 'react';

import { useChart } from '@/contexts/chart-context';

import { periodMap } from '.';
import { getMoodColor } from './Points';

type Props = {
   line: d3.Line<any>;
   transitionDuration: number;
   xScale: d3.ScaleTime<number, number>;
};

export const ChartFullLine = ({ line, transitionDuration, xScale }: Props) => {
   const { data, period } = useChart();
   const lineFiltered = data.filter(line.defined());
   const lineAttrs = line(lineFiltered);

   const dataPoints = data.filter(
      (d) =>
         ('score' in d && !!d.score) ||
         ('averageScore' in d && !!d.averageScore),
   );

   if (dataPoints.length < 2) {
      return null;
   }

   const lineWidth =
      xScale(dataPoints[dataPoints.length - 1].date) -
      xScale(dataPoints[0].date);

   const lineXScale = d3
      .scaleLinear()
      .domain([dataPoints[0].date, dataPoints[dataPoints.length - 1].date])
      .range([0, lineWidth]);

   const firstPoint = dataPoints[0];
   const firstScore =
      'score' in firstPoint
         ? firstPoint.score || 0
         : 'averageScore' in firstPoint
           ? firstPoint.averageScore || 0
           : 0;

   const isAllSameScore = !dataPoints.find((dataPoint) => {
      const score =
         'score' in dataPoint
            ? dataPoint.score || 0
            : 'averageScore' in dataPoint
              ? dataPoint.averageScore || 0
              : 0;

      return score !== firstScore;
   });

   return lineAttrs ? (
      <>
         {dataPoints.length > 1 && (
            <defs>
               <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                  {dataPoints.map((dataPoint, i) => {
                     const score =
                        'score' in dataPoint
                           ? dataPoint.score || 0
                           : 'averageScore' in dataPoint
                             ? dataPoint.averageScore || 0
                             : 0;

                     const offsetPercentage =
                        (lineXScale(dataPoint.date) / lineWidth) * 100;
                     return (
                        <Fragment key={i}>
                           {i > 0 && (
                              <stop
                                 offset={`${
                                    offsetPercentage -
                                    periodMap[period]
                                       .lineGradientOffsetPercentage
                                 }%`}
                                 stopColor={getMoodColor(score)}
                              />
                           )}
                           <stop
                              offset={`${offsetPercentage}%`}
                              stopColor={getMoodColor(score)}
                           />
                           {i + 1 < dataPoints.length && (
                              <stop
                                 offset={`${
                                    offsetPercentage +
                                    periodMap[period]
                                       .lineGradientOffsetPercentage
                                 }%`}
                                 stopColor={getMoodColor(score)}
                              />
                           )}
                        </Fragment>
                     );
                  })}
               </linearGradient>
            </defs>
         )}
         <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{
               duration: transitionDuration,
               delay: 0.3,
               type: 'spring',
            }}
            opacity={1}
            d={lineAttrs}
            fill="none"
            stroke={isAllSameScore ? getMoodColor(firstScore) : 'url(#linear)'}
            strokeWidth={3}
         />
      </>
   ) : null;
};
