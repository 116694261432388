import { Box, Flex, Text } from '@chakra-ui/react';
import { type MoodTrackingEnum } from '@innerwell/dtos';

import { type MoodIconName } from '@/utils/weekly-mood-options';

import { Icon } from '../Icon';

type Props = {
   title: string;
   subTitle: string;
   iconName?: MoodIconName | 'mood-add';
   mood?: MoodTrackingEnum;
   bg?: string;
   iconColor?: string;
   iconText?: string;
   applyBlurToIcon?: boolean;
   hasStroke?: boolean;
   onIconClick?: () => void;
};

export const MoodCard = ({
   bg = 'white',
   iconColor = bg,
   title,
   subTitle,
   iconName,
   iconText,
   hasStroke = false,
   applyBlurToIcon = false,
   onIconClick,
}: Props) => {
   return (
      <Flex
         pos="relative"
         alignItems="center"
         py={3}
         px="18px"
         borderRadius="8px"
         overflow="hidden"
         outline={hasStroke ? '1px solid red' : 'none'}
         outlineColor={hasStroke ? 'line.primary' : 'none'}
      >
         <Box
            pos="absolute"
            opacity={0.1}
            left={0}
            top={0}
            zIndex={1}
            w="full"
            h="full"
            bg={bg}
         />
         <Flex
            zIndex={2}
            pos="relative"
            alignItems="center"
            gap={1.5}
            fontSize="xs"
            flex={1}
         >
            <Flex flexDir="column">
               <Text fontWeight={500}>{title}</Text>
               <Text>{subTitle}</Text>
            </Flex>
            <Flex gap={1.5} ml="auto" alignItems="center">
               {iconName ? (
                  <Icon
                     name={iconName}
                     ml={3}
                     w={8}
                     h={8}
                     filter={applyBlurToIcon ? 'blur(4px)' : 'none'}
                     onClick={onIconClick}
                     cursor={onIconClick ? 'pointer' : 'default'}
                     color={iconColor}
                  />
               ) : null}
               {iconText ? (
                  <Box bg={bg} borderRadius="4px" px={1.5}>
                     <Text opacity={0.75} color="white" fontWeight={600}>
                        {iconText}
                     </Text>
                  </Box>
               ) : null}
            </Flex>
         </Flex>
      </Flex>
   );
};
