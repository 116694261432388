import { useInfiniteQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

const MOODS_PER_PAGE: number = process.env.NODE_ENV === 'development' ? 10 : 2;

export const useInfiniteDailyMoods = () => {
   return useInfiniteQuery({
      queryKey: queryKeys.dailyMoods,
      queryFn: async ({ pageParam }) => {
         const response = await webApiClient.moodTracking.getMoodTracking({
            query: {
               page: pageParam,
               size: MOODS_PER_PAGE,
            },
         });

         return {
            moodData: response.body.data,
            nextPage:
               response.body.data.length >= MOODS_PER_PAGE
                  ? pageParam + 1
                  : undefined,
         };
      },
      initialPageParam: 0,

      getPreviousPageParam: (_firstPage) => {
         return 1;
      },
      getNextPageParam: (lastPage) => {
         return lastPage.nextPage;
      },
      refetchOnMount: true,
   });
};
