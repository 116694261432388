import { type MoodTrackingRangeEnum } from '@innerwell/dtos';
import { getUtcDateFromTz } from '@innerwell/utils';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import {
   type ChartPeriod,
   periodMap,
} from '@/components/Charts/DailyMoodChart';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

type UsePeriodMoodTrackingProps = {
   period: ChartPeriod;
   periodSegment: MoodTrackingRangeEnum;
};

export const usePeriodMoodTracking = ({
   period,
   periodSegment,
}: UsePeriodMoodTrackingProps) => {
   return useQuery({
      queryKey: queryKeys.moodTrackingByPeriod(period),
      queryFn: async () => {
         const daysAmount = periodMap[period].amountOfDays;

         const dateStart = getUtcDateFromTz(
            DateTime.now().startOf('day').toUTC(),
         ).minus({ days: daysAmount - (period === '6m' ? 1 : 2) });

         const dateEnd = getUtcDateFromTz(DateTime.now().startOf('day')).plus({
            days: period === '6m' ? 1 : 2,
         });

         const response = await webApiClient.moodTracking.progress({
            query: {
               dateStart: dateStart.toISODate(),
               dateEnd: dateEnd.toISODate(),
               period: periodSegment,
            },
         });

         return response.body;
      },
   });
};
